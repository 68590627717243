import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  notification,
  Spin,
} from 'antd';
import {
  Card,
  Drawer,
  Input,
} from 'components/gyramais';
import {
  numberToMonth,
  formatError,
} from 'utils';
import {
  useMutation,
} from '@apollo/client';
import UploadCard from 'components/cards/UploadCard';
import {
  SAVE_BUSINESS,
  SAVE_LOAN_EVENT,
} from './graphql';
import './styles.less';

const RevenueStatementDrawer = ({
  visible,
  onClose,
  businessId,
  statements: businessStatements,
  loanId,
  revenueStatement,
  refetch,
  loading: businessLoading,
}) => {
  const [statements, setStatements] = useState([]);
  const [statementsFilesIds, setStatementsFilesIds] = useState(false);

  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: async () => {
      notification.success({ message: 'Empréstimo salvo com sucesso', duration: 10 });
      await refetch();
      onClose();
      refetch();
    },
    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),
  });

  const [saveBusiness] = useMutation(SAVE_BUSINESS, {
    onCompleted: ({ saveBusiness: { revenueStatement } }) => {
      notification.success({ message: 'Arquivo alterado com sucesso', duration: 10 });
      refetch();
      if (revenueStatement.length) {
        setStatementsFilesIds(true);
      }
    },
    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),
  });

  const validateStatements = (statements) => {
    const statementsOrdered = statements.sort((a, b) => a.reference - b.reference).slice(12);

    const statementsWithValue = statementsOrdered.filter(({ value }) => Boolean(Number(value)));
    return statementsWithValue.length >= 7;
  };

  useEffect(() => {
    const emptyStatements = [];
    for (let x = 1; x <= 24; x += 1) {
      const auxDate = new Date();
      const date = new Date(auxDate.getFullYear(), auxDate.getMonth() - x, 1, 0, 0, 0, 0);
      const mesano = `${date.getMonth() + 1}${date.getFullYear()}`;
      const statement = businessStatements
        ?.find(({ mesano: statementMesano }) => statementMesano === mesano);
      emptyStatements.push({
        id: x,
        label: `${numberToMonth(date.getMonth() + 1)} de ${date.getFullYear()}`,
        reference: new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`),
        value: statement?.value || 0,
        mesano,
      });
    }
    setStatements(emptyStatements.reverse());

  }, [businessStatements, visible]);

  useEffect(() => {
    if (revenueStatement.length) {
      setStatementsFilesIds(true);
    } else {
      setStatementsFilesIds(false);
    }
  }, [revenueStatement]);

  const loading = businessLoading || saveLoanLoading;

  return (
    <>
      <Drawer
        title="Faturamento dos últimos 24 meses"
        visible={visible}
        primaryButton={{
          children: 'Confirmar',
          loading,
          onClick: async () => {
            if (!validateStatements(statements)) {
              return notification.error({
                message: 'Preencha ao menos 7 campos de faturamento do ultimo ano para prosseguir',
              });
            }
            if (!statementsFilesIds) {
              return notification.error({
                message: 'Envie o arquivo de faturamento dos últimos 24 meses',
              });
            }

            await saveBusiness({
              variables: {
                id: businessId,
                revenueStatementValues: statements.map(({
                  reference, value, mesano,
                }) => ({ reference, value, mesano })),
              },
            });

            saveLoanEvent({
              variables: {
                loanId,
                statusValue: 'integrated',
              },
            });

            onClose();
            return true;
          },
        }}
        maxWidth="100%"
        onClose={onClose}
      >
        <Spin spinning={loading}>
          <Card className="statements-inputs-card">
            {statements.map(({ label, id: index, value }) => (
              <Row align="middle" key={index} className="statement-input-area" gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="statment-label">{`Valor em ${label}`}</div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    type="currency"
                    value={value}
                    onChange={async (value) => {
                      const statementId = statements.findIndex(({ id }) => id === index);
                      const all = statements;
                      all[statementId].value = value;
                      setStatements([...all]);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </Card>

          <br />
          <br />
          <br />

          <UploadCard
            id="revenue-statement-upload"
            accept="application/pdf, .pdf"
            actualFile={revenueStatement}
            loading={loading}
            multiples
            refetch={refetch}
            alert="Relação de faturamento dos últimos 24 meses, mês a mês"
            label="Faturamento dos últimos 24 meses"
            onCompleted={async ({ id: fileId }) => {
              await saveBusiness({
                variables: {
                  id: businessId,
                  revenueStatementFilesIds:
                    [...revenueStatement?.map((i) => i.id), fileId],
                },
              });
            }}
          />
        </Spin>
      </Drawer>
    </>
  );
};

RevenueStatementDrawer.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
  statements: PropTypes.arrayOf(PropTypes.shape()),
  revenueStatement: PropTypes.arrayOf(PropTypes.shape()),
  businessId: PropTypes.string,
  loanId: PropTypes.string,
};

export default RevenueStatementDrawer;
